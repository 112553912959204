<template>
  <el-container class="bodybox">
    <el-header>
      <header-menu active="/" />
    </el-header>
    <el-main class="mainbox">
      <div class="indexbox">
        <div class="searchbox">
          <div class="box">
            <el-button class="search" @click="reSync" :loading="loadingb">Sync</el-button>
          </div>
        </div>
        <div class="tablebox">
          <div class="draggable" v-loading="loading">
            <el-table :data="tableData" :height="height">
              <el-table-column prop="created_at" label="Sync DateTime" min-width="160" show-overflow-tooltip
                align="center"></el-table-column>·
              <el-table-column prop="sync_type" label="SyncType" min-width="200" show-overflow-tooltip align='center'>
                <template slot-scope="scope">
                  <p v-if="scope.row.sync_type == 1">Auto-PO</p>
                  <p v-if="scope.row.sync_type == 2">Auto-SI</p>
                  <p v-if="scope.row.sync_type == 3">Auto-Product</p>
                  <p v-if="scope.row.sync_type == 4">Auto-PaymentReceived</p>
                </template>
              </el-table-column>
              <el-table-column prop="status" label="Status" min-width="100" show-overflow-tooltip align='center'>
                <template slot-scope="scope">
                  <el-tag v-if="scope.row.status == 1" type="success">Success</el-tag>
                  <el-tag v-if="scope.row.status == 2" type="danger">Fail</el-tag>
                </template>
              </el-table-column>
              <el-table-column prop="description" label="Description" min-width="220" show-overflow-tooltip
                align='center'></el-table-column>
              <el-table-column prop="reference" label="Reference" min-width="180" show-overflow-tooltip
                align='center'></el-table-column>
              <el-table-column prop="" label="Operation" min-width="100" align='center'>
                <template slot-scope="scope">
                  <el-button v-if="scope.row.status == 2 && scope.row.is_handle == 0" class="search"
                    @click="change(scope.row)">ReSync</el-button>
                </template>
              </el-table-column>
            </el-table>
          </div>
          <el-pagination @current-change="pagechage" @size-change="handleSizeChange" :page-sizes="[10, 20, 50, 100]"
            :page-size="page_size" background layout="total,sizes,prev, pager, next" :current-page.sync="page"
            :total="total" />
        </div>
      </div>
    </el-main>
  </el-container>
</template>
<script>
  import HeaderMenu from "@/components/HeaderMenu"
  export default {
    components: { HeaderMenu },
    data() {
      return {
        height: 660,
        total: 0,
        page: 1,
        page_size: 100,
        tableData: [],
        loading: false,
        loadingb: false,
      }
    },
    mounted() {
      this.getTableHeight()
      window.addEventListener("resize", this.getTableHeight)
    },
    destroyed() {
      window.removeEventListener("resize", this.getTableHeight)
    },
    created() {
      this.getlist()
    },
    methods: {
      getlist() {
        this.loading = true
        this.$get({
          url: "/log/list",
          data: {
            page: this.page,
            page_limit: this.page_size,
          },
          success: (res) => {
            this.tableData = res.data.data
            this.total = res.data.total
            setTimeout(() => {
              this.loading = false
            }, 200)
          },
          tip: () => {
            this.loading = false
          },
        })
      },
      pagechage(page) {
        this.page = page
        this.tableData = []
        this.getlist()
      },
      handleSizeChange(size) {
        this.page_size = size
        this.tableData = []
        this.getlist()
      },
      reSync() {
        this.loadingb = true
        this.$get({
          url: "/reSync",
          success: (res) => {
            this.loadingb = false
            this.pagechage(1)
          },
          tip: () => { this.loadingb = false },
        })
      },
      change(data) {
        this.loading = true
        this.$get({
          url: "/reSyncErrorLog",
          data: { id: data.id },
          success: (res) => {
            this.pagechage(1)
          },
          tip: () => { this.loadingb = false },
        })
      },
      getTableHeight() {
        this.$nextTick(() => {
          let searchbox = document.getElementsByClassName("searchbox")[0]
          let mainbox = document.getElementsByClassName("mainbox")[0]
          let pagination = document.getElementsByClassName("el-pagination")[0]
          this.height = mainbox.offsetHeight - searchbox.offsetHeight - pagination.offsetHeight - 55
        })
      },
    },
  }
</script>
<style lang="scss" scoped>
  .mainbox {
    .indexbox {
      width: 100%;
      height: 100%;
      .searchbox {
        width: 100%;
        padding: 10px 0 0 20px;
        background: #fff;
        border-bottom: 1px solid #babdcd;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        flex-wrap: wrap;
        .box {
          padding: 0 20px 10px 0;
        }
        .small {
          width: 130px;
        }
        .inp {
          width: 400px;
          /deep/.el-input__inner {
            background: #edeff8;
          }
        }
        /deep/.el-button {
          border-radius: 10px;
          color: #3b3b3b;
          font-size: 16px;
        }
        .search {
          background: #d6f3e3;
          border-color: #d6f3e3;
        }
        .reset {
          background: #f3d6d6;
          border-color: #f3d6d6;
        }
      }
      .tablebox {
        width: 100%;
        padding: 20px 20px 10px;
      }
      .formbox {
        /deep/.el-form-item {
          margin-bottom: 30px !important;
          .el-form-item__content {
            width: 100%;
            .el-button {
              width: 100%;
              height: 48px;
              background: $color;
              border-radius: 5px;
              border-color: $color;
            }
          }
        }
      }
      .dataselect {
        width: 100%;
        display: flex;
        justify-content: space-between;
        align-items: center;
      }
      /deep/.el-dialog {
        min-width: 400px !important;
      }
      .act {
        color: blue;
        font-weight: 600;
        cursor: pointer;
      }
    }
  }
</style>
